<template>
	<div class="vaccinationsClass">
		<div class="basicInfo">
			<p class="title">基本信息</p>
			<div class="basicInfo-content">
				<div class="basicInfo-content-left clearfix">
					<p class="float-l">姓名</p>
					<p class="float-l">{{ $route.query.name || '-' }}</p>
					<p class="float-l">性别</p>
					<p class="float-l">{{ $route.query.sex || '-' }}</p>
					<p class="float-l">年龄</p>
					<p class="float-l">{{ $route.query.age || '-' }}</p>
					<p class="float-l">民 族</p>
					<p class="float-l">{{ $route.query.ethnicity || '-' }}</p>
				</div>
				<img v-if="!$route.query.link || $route.query.link == ''" src="@/assets/imgs/recordForm.png" alt="" />
				<img v-else :src="$route.query.link" alt="" />
			</div>
		</div>
		<div class="vaccinationsInfo">
			<p class="title">国家免疫规划疫苗儿童免疫程序表</p>
			<div class="vaccinationsInfo-content">
				<el-table :data="tableData" border style="width: 100%">
					<el-table-column label="疫苗种类" width="348">
						<template slot-scope="scope">
							{{
								scope.row.vaccineTypeDictionaryItem.title +
								'(' +
								scope.row.vaccineTypeDictionaryItem.code +
								')' +
								'第' +
								scope.row.sequenceNumber +
								'针'
							}}
						</template>
					</el-table-column>
					<el-table-column label="预计接种时间">
						<template slot-scope="scope">
							{{ scope.row.vaccinateStartTime.slice(0, 10) }}
						</template>
					</el-table-column>
					<el-table-column label="实际接种时间">
						<template slot-scope="scope">
							{{ scope.row.vaccinateTime.slice(0, 10) == '2000-01-01' ? '未接种' : scope.row.vaccinateTime.slice(0, 10) }}
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			tableData: [],
		};
	},
	mounted() {
		this.getDeatils();
	},
	methods: {
		// 详情
		getDeatils() {
			if (!this.$route.query.id) return;

			this.$http
				.get(this.api['Vaccinations#index'].href.slice(0, -5) + '/' + this.$route.query.id + '.json')
				.then((res) => {
					if (res.data && res.data.success) {
						this.tableData = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
	},
};
</script>

<style lang="scss" scoped>
.vaccinationsClass {
	width: 80%;
	margin: 16px auto;
	background-color: #fff;
	padding: 24px 40px;
	.title {
		font-size: 16px;
		color: #333333;
		padding-left: 8px;
		border-left: 3px solid #1db9b1;
		line-height: 16px;
		margin-bottom: 16px;
	}
	.basicInfo {
		margin-bottom: 40px;
		&-content {
			display: flex;
			&-left {
				width: calc(100% - 112px);
				border-bottom: 1px solid rgba(214, 217, 224, 0.8);
				p {
					color: #333;
					padding-left: 16px;
					line-height: 48px;
					height: 48px;
					border: 1px solid rgba(214, 217, 224, 0.8);
					width: calc(50% - 165px);
					border-bottom: none;
					border-right: none;
					&:nth-child(2n-1) {
						width: 165px;
						background-color: #f4f6fd;
						border-right: none;
					}
				}
			}
			img {
				width: 96px;
				height: 96px;
				margin-left: 16px;
			}
		}
	}
	/deep/ .el-table {
		thead {
			th {
				background-color: #f4f6fd;
				font-weight: normal;
				color: #333;
			}
		}
		tr:hover {
			> td:not(:first-child) {
				background-color: #f4f6fd !important;
			}
		}
	}
}
</style>